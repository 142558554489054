import React from "react";
import Sidebar from "../component/sidebar";
import Navbar from "../component/navbar";

const payment = [
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
  {
    invoice: "Facture#1",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    btn: "succès",
    download: "télécharger",
  },
];

const product = [
  {
    invoice: "Example",
    amount: "$2000.00 per month",

    btn: "payer",
  },
  {
    invoice: "Example",
    amount: "$2000.00 per month",

    btn: "payer",
  },
  {
    invoice: "Example",
    amount: "$2000.00 per month",

    btn: "payer",
  },
  {
    invoice: "Example",
    amount: "$2000.00 per month",

    btn: "payer",
  },
  {
    invoice: "Example",
    amount: "$2000.00 per month",

    btn: "payer",
  },
  {
    invoice: "Example",
    amount: "$2000.00 per month",

    btn: "payer",
  },
  {
    invoice: "Example",
    amount: "$2000.00 per month",

    btn: "payer",
  },
  {
    invoice: "Example",
    amount: "$2000.00 per month",

    btn: "payer",
  },
];

const service = [
  {
    services: "Health",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    invoice: "invoice#1",
    download: "télécharger",
  },
  {
    services: "Health",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    invoice: "invoice#1",
    download: "télécharger",
  },
  {
    services: "Health",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    invoice: "invoice#1",
    download: "télécharger",
  },
  {
    services: "Health",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    invoice: "invoice#1",
    download: "télécharger",
  },
  {
    services: "Health",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    invoice: "invoice#1",
    download: "télécharger",
  },
  {
    services: "Health",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    invoice: "invoice#1",
    download: "télécharger",
  },
  {
    services: "Health",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    invoice: "invoice#1",
    download: "télécharger",
  },
  {
    services: "Health",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    invoice: "invoice#1",
    download: "télécharger",
  },
  {
    services: "Health",
    amount: "$2000.00",
    date: "Nov 04, 2023",
    invoice: "invoice#1",
    download: "télécharger",
  },
];

const request = [
  {
    date: "Nov 04, 2023",
    example: "example",
    afternoon: "Afternoon",
    appro: "Approved",
    cla: "View Calendar",
  },
  {
    date: "Nov 04, 2023",
    example: "example",
    afternoon: "Afternoon",
    appro: "Approved",
    cla: "View Calendar",
  },

  {
    date: "Nov 04, 2023",
    example: "example",
    afternoon: "Afternoon",
    appro: "Approved",
    cla: "View Calendar",
  },
  {
    date: "Nov 04, 2023",
    example: "example",
    afternoon: "Afternoon",
    appro: "Approved",
    cla: "View Calendar",
  },

  {
    date: "Nov 04, 2023",
    example: "example",
    afternoon: "Afternoon",
    appro: "Approved",
    cla: "View Calendar",
  },
  {
    date: "Nov 04, 2023",
    example: "example",
    afternoon: "Afternoon",
    appro: "Approved",
    cla: "View Calendar",
  },

  {
    date: "Nov 04, 2023",
    example: "example",
    afternoon: "Afternoon",
    appro: "Approved",
    cla: "View Calendar",
  },

  {
    date: "Nov 04, 2023",
    example: "example",
    afternoon: "Afternoon",
    appro: "Approved",
    cla: "View Calendar",
  },

  {
    date: "Nov 04, 2023",
    example: "example",
    afternoon: "Afternoon",
    appro: "Approved",
    cla: "View Calendar",
  },
];

export default function PersonalInfo() {
  return (
    <>
      <div className="grid lg:grid-cols-12 ">
        <div className="lg:col-span-2 2xl:col-span-2 ">
          <Sidebar />
        </div>
        <div className="lg:col-span-10 2xl:col-span-10 h-[100vh] overflow-y-scroll">
          <Navbar />

          <div className="w-[95%] mx-auto py-5 mt-20">
            <p className="font-inter font-[700] text-[25px] text-black">
            données personnelles
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-5 gap-5">
              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                prénom
                </p>
                <input
                  type="text"
                  placeholder="Ahsan"
                  className="mt-1 p-2 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                nom de famille
                </p>
                <input
                  type="text"
                  placeholder="Ahsan"
                  className="mt-1 p-2 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">
                Numéro de téléphone
                </p>
                <input
                  type="number"
                  placeholder="000000000"
                  className="mt-1 p-2 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>

              <div>
                <p className="font-[400] text-[10px] text-gray-500">Enfants</p>
                <input
                  type="number"
                  placeholder="1"
                  className="mt-1 p-2 border-[1px] border-gray-500 rounded-md w-full"
                />
              </div>
            </div>

            <div className="grid lg:grid-cols-2 gap-10">
              <div className="mt-10 ">
                <div className="flex justify-between">
                  <p className="font-inter font-[700] text-[15px] md:text-[18px] text-black">
                  historique des paiements
                  </p>

                  <p className="self-center cursor-pointer font-inter font-[500] text-[15px] md:text-[18px] text-[#5E83F5]">
                  Voir tout
                  </p>
                </div>

                <div>
                  <div className=" bg-[#BFD5FF] hidden md:grid  grid-cols-12  py-3 rounded-t-lg mt-3">
                    <div className="col-span-3 text-center">
                      <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      Facture de paiement
                        {/* paiement est */}
                      </p>
                    </div>
                    <div className="col-span-2  text-center">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      Montante
                        {/* quantité */}
                      </p>
                    </div>
                    <div className="col-span-3 text-center">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        datte
                      </p>
                    </div>
                    <div className="col-span-2 text-center ">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        statut
                      </p>
                    </div>
                    <div className="col-span-2 text-center ">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        {/* Download */}
                        télécharger
                      </p>
                    </div>
                  </div>

                  {payment.map((item) => (
                    <div className="hidden md:grid grid-cols-12  py-4 border-[1px] border-gray-300 bg-white">
                      <div className="col-span-3  flex gap-2 justify-center">
                        <input type="checkbox" />
                        <p className=" self-center font-inter font-[500] text-[14px] text-[#2E3646]">
                          {item.invoice}
                        </p>
                      </div>
                      <div className="col-span-2 self-center">
                        <p className="text-center font-inter font-[500] text-[14px] text-[#2E3646]">
                          {item.amount}
                        </p>
                      </div>
                      <div className="col-span-3 self-center">
                        <p className="text-center font-inter font-[500] text-[14px] text-[#2E3646]">
                          {item.date}
                        </p>
                      </div>
                      <div className="col-span-2 ">
                        <button className="font-inter font-[400] text-[13px] text-[#5E83F5] border-[1px] border-[#5E83F5] rounded-full bg-[#e5ebf9] py-1 px-5 block mx-auto">
                          {item.btn}
                        </button>
                      </div>
                      <div className="col-span-2 self-center">
                        <p className="cursor-pointer text-center font-inter font-[500] text-[14px] text-[#5E83F5]">
                          {item.download}
                        </p>
                      </div>
                    </div>
                  ))}

                  {payment.map((item) => (
                    <div className=" grid md:hidden mt-5   py-5 border-[1px] border-gray-300 bg-white rounded-lg">
                      <div className=" flex gap-2 justify-center">
                        <input type="checkbox" className="mt-1" />
                        <p className="self-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.invoice}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.amount}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.date}
                        </p>
                      </div>
                      <div className="mt-3">
                        <button className="font-inter font-[400] text-[16px] text-[#5E83F5] border-[1px] border-[#5E83F5] rounded-full bg-[#e5ebf9] py-1 px-5 block mx-auto">
                          {item.btn}
                        </button>
                      </div>
                      <div className="mt-3">
                        <p className="cursor-pointer text-center font-inter font-[500] text-[16px] text-[#5E83F5]">
                          {item.download}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-10 ">
                <div className="flex justify-between">
                  <p className="font-inter font-[700] text-[15px] md:text-[18px] text-black">
                  produit
                  </p>

                  <p className="self-center cursor-pointer font-inter font-[500] text-[15px] md:text-[18px] text-[#5E83F5]">
                  Voir tout
                    
                  </p>
                </div>

                <div>
                  <div className=" bg-[#BFD5FF] hidden md:grid  grid-cols-12  py-3 rounded-t-lg mt-3">
                    <div className="col-span-4 text-center">
                      <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      Produit détenu
                      </p>
                    </div>
                    <div className="col-span-4  text-center">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      
                        quantité
                      </p>
                    </div>
                    <div className="col-span-4 text-center">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      acheter
                      </p>
                    </div>
                  </div>

                  {product.map((item) => (
                    <div className="hidden md:grid grid-cols-12  py-[15px] border-[1px] border-gray-300 bg-white">
                      <div className="col-span-4  flex gap-2 justify-center">
                        <input type="checkbox" />
                        <p className=" self-center font-inter font-[500] text-[14px] text-[#2E3646]">
                          {item.invoice}
                        </p>
                      </div>
                      <div className="col-span-4 self-center">
                        <p className="text-center font-inter font-[500] text-[14px] text-[#2E3646]">
                          {item.amount}
                        </p>
                      </div>

                      <div className="col-span-4 ">
                        <button className="font-inter font-[400] text-[13px] text-white  rounded-full bg-[#5E83F5] py-[6px] px-5 block mx-auto">
                          {item.btn}
                        </button>
                      </div>
                    </div>
                  ))}

                  {product.map((item) => (
                    <div className=" grid md:hidden mt-5   py-5 border-[1px] border-gray-300 bg-white rounded-lg">
                      <div className=" flex gap-2 justify-center">
                        <input type="checkbox" className="" />
                        <p className="self-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.invoice}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.amount}
                        </p>
                      </div>
                      <div className="mt-3">
                        <button className="font-inter font-[400] text-[13px] text-white  rounded-full bg-[#5E83F5] py-[6px] px-5 block mx-auto">
                          {item.btn}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-10 ">
                <div className="flex justify-between">
                  <p className="font-inter font-[700] text-[15px] md:text-[18px] text-black">
                  dossier de service
                  </p>

                  <p className="self-center cursor-pointer font-inter font-[500] text-[15px] md:text-[18px] text-[#5E83F5]">
                  Voir tout
                  </p>
                </div>

                <div>
                  <div className=" bg-[#BFD5FF] hidden md:grid  grid-cols-12  py-3 rounded-t-lg mt-3">
                    <div className="col-span-3 text-center">
                      <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        Services
                        {/* paiement est */}
                      </p>
                    </div>
                    <div className="col-span-2  text-center">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        prix
                        {/* quantité */}
                      </p>
                    </div>
                    <div className="col-span-3 text-center">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        datte
                      </p>
                    </div>
                    <div className="col-span-2 text-center ">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      Facture
                    
                      </p>
                    </div>
                    <div className="col-span-2 text-center ">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        télécharger
                      </p>
                    </div>
                  </div>

                  {service.map((item) => (
                    <div className="hidden md:grid grid-cols-12  py-[21px] border-[1px] border-gray-300 bg-white">
                      <div className="col-span-3  flex gap-2 justify-center">
                        <input type="checkbox" />
                        <p className=" self-center font-inter font-[700] text-[12px] text-[#2E3646]">
                          {item.services}
                        </p>
                      </div>
                      <div className="col-span-2 self-center">
                        <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                          {item.amount}
                        </p>
                      </div>
                      <div className="col-span-3 self-center">
                        <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                          {item.date}
                        </p>
                      </div>
                      <div className="col-span-2 ">
                        <p className=" self-center text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                          {item.invoice}
                        </p>
                      </div>
                      <div className="col-span-2 self-center">
                        <p className="cursor-pointer text-center font-inter font-[500] text-[12px] text-[#5E83F5]">
                          {item.download}
                        </p>
                      </div>
                    </div>
                  ))}

                  {service.map((item) => (
                    <div className=" grid md:hidden mt-5   py-5 border-[1px] border-gray-300 bg-white rounded-lg">
                      <div className=" flex gap-2 justify-center">
                        <input type="checkbox" className="mt-1" />
                        <p className="self-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.services}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.amount}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.date}
                        </p>
                      </div>
                      <div className="mt-3">
                        <button className="font-inter font-[400] text-[16px] text-[#5E83F5] border-[1px] border-[#5E83F5] rounded-full bg-[#e5ebf9] py-1 px-5 block mx-auto">
                          {item.invoice}
                        </button>
                      </div>
                      <div className="mt-3">
                        <p className="cursor-pointer text-center font-inter font-[500] text-[16px] text-[#5E83F5]">
                          {item.download}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-10">
                <div className="flex justify-between">
                  <p className="font-inter font-[700] text-[15px] md:text-[18px] text-black">
                  Historique de la demande
                  </p>

                  <p className="self-center cursor-pointer font-inter font-[500] text-[15px] md:text-[18px] text-[#5E83F5]">
                  Voir tout
                  </p>
                </div>

                <div>
                  <div className=" bg-[#BFD5FF] hidden md:grid  grid-cols-12  py-3 rounded-t-lg mt-3">
                    <div className="col-span-3 text-center">
                      <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      Natanggap Sa
                        {/* paiement est */}
                      </p>
                    </div>
                    <div className="col-span-2  text-center">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      Hiniling
                        {/* quantité */}
                      </p>
                    </div>
                    <div className="col-span-3 text-center">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      Demande de blocage
                      </p>
                    </div>
                    <div className="col-span-2 text-center ">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                        
                        statut
                      </p>
                    </div>
                    <div className="col-span-2 text-center ">
                      <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      Facture
                        
                      </p>
                    </div>
                  </div>

                  {request.map((item) => (
                    <div className="hidden md:grid grid-cols-12  py-4 border-[1px] border-gray-300 bg-white">
                      <div className="col-span-3 self-center">
                        <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                          {item.date}
                        </p>
                      </div>

                      <div className="col-span-2 self-center">
                        <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                          {item.example}
                        </p>
                      </div>

                      <div className="col-span-3 self-center">
                        <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                          {item.afternoon}
                        </p>
                      </div>
                      <div className="col-span-2  self-center">
                        <button className="font-inter font-[400] text-[12px] text-[#5E83F5] border-[1px] border-[#5E83F5] rounded-full bg-[#e5ebf9] py-1 px-4 block mx-auto">
                          {item.appro}
                        </button>
                      </div>
                      <div className="col-span-2 self-center">
                        <p className="cursor-pointer text-center font-inter font-[500] text-[12px] text-[#5E83F5]">
                          {item.cla}
                        </p>
                      </div>
                    </div>
                  ))}
                  {request.map((item) => (
                    <div className=" grid md:hidden mt-5   py-5 border-[1px] border-gray-300 bg-white rounded-lg">
                      <div className=" flex gap-2 justify-center">
                        <p className="self-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.date}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.example}
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                          {item.afternoon}
                        </p>
                      </div>
                      <div className="mt-3">
                      <button className="font-inter font-[400] text-[12px] text-[#5E83F5] border-[1px] border-[#5E83F5] rounded-full bg-[#e5ebf9] py-1 px-4 block mx-auto">
                          {item.appro}
                        </button>
                      </div>
                      <div className="mt-3">
                        <p className="cursor-pointer text-center font-inter font-[500] text-[16px] text-[#5E83F5]">
                          {item.cla}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
