import React from "react";
import Sidebar from "../component/sidebar";
import Navbar from "../component/navbar";
import Calendar from "../assets/png/Calendar.png";

const request = [
  {
    date: "Budiakala Fogo",
    example: "Heart Failure",
    afternoon: "Nov 04, 2023",
    appro: "complété",
  },
  {
    date: "Budiakala Fogo",
    example: "Heart Failure",
    afternoon: "Nov 04, 2023",
    appro: "complété",
  },

  {
    date: "Budiakala Fogo",
    example: "Heart Failure",
    afternoon: "Nov 04, 2023",
    appro: "complété",
  },

  {
    date: "Budiakala Fogo",
    example: "Heart Failure",
    afternoon: "Nov 04, 2023",
    appro: "complété",
  },

  {
    date: "Budiakala Fogo",
    example: "Heart Failure",
    afternoon: "Nov 04, 2023",
    appro: "complété",
  },

  {
    date: "Budiakala Fogo",
    example: "Heart Failure",
    afternoon: "Nov 04, 2023",
    appro: "complété",
  },

  {
    date: "Budiakala Fogo",
    example: "Heart Failure",
    afternoon: "Nov 04, 2023",
    appro: "complété",
  },
];

export default function Medical() {
  return (
    <>
      <div className="grid lg:grid-cols-12 ">
        <div className="lg:col-span-2 2xl:col-span-2 ">
          <Sidebar />
        </div>
        <div className="lg:col-span-10 2xl:col-span-10 h-[100vh] overflow-y-scroll">
          <Navbar />
          <div className="w-[95%] mx-auto pt-10 mt-20 ">
            <p className="font-inter font-[700] text-[25px] text-black">
            Mon dossier médical
            </p>

            <div className="mt-10">
              <div className="flex justify-between">
                <p className="font-inter font-[700] text-[15px] md:text-[18px] text-black">
                Histoire de la consultation
                </p>

                <p className="self-center cursor-pointer font-inter font-[500] text-[15px] md:text-[18px] text-[#5E83F5]">
                Voir tout
                </p>
              </div>

              <div>
                <div className=" bg-[#BFD5FF] hidden md:grid  grid-cols-12  py-3 rounded-t-lg mt-3">
                  <div className="col-span-3 text-center">
                    <p className="font-inter font-[500] text-[12px] text-[#5F6D7E]">
                    Nom du patient
                    </p>
                  </div>
                  <div className="col-span-3  text-center">
                    <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                    Traitement
                      {/* quantité */}
                    </p>
                  </div>
                  <div className="col-span-3 text-center">
                    <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      datte
                    </p>
                  </div>
                  <div className="col-span-3 text-center ">
                    <p className=" font-inter font-[500] text-[12px] text-[#5F6D7E]">
                      
                      statut
                    </p>
                  </div>
                </div>

                {request.map((item) => (
                  <div className="hidden md:grid grid-cols-12  py-4 border-[1px] border-gray-300 bg-white">
                    <div className="col-span-3 self-center">
                      <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                        {item.date}
                      </p>
                    </div>

                    <div className="col-span-3 self-center">
                      <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                        {item.example}
                      </p>
                    </div>

                    <div className="col-span-3 self-center">
                      <p className="text-center font-inter font-[500] text-[12px] text-[#2E3646]">
                        {item.afternoon}
                      </p>
                    </div>
                    <div className="col-span-3  self-center">
                      <button className="font-inter font-[400] text-[12px] text-[#5E83F5] border-[1px] border-[#5E83F5] rounded-full bg-[#e5ebf9] py-1 px-4 block mx-auto">
                        {item.appro}
                      </button>
                    </div>
                  </div>
                ))}
                {request.map((item) => (
                  <div className=" grid md:hidden mt-5   py-5 border-[1px] border-gray-300 bg-white rounded-lg">
                    <div className=" flex gap-2 justify-center">
                      <p className="self-center font-inter font-[500] text-[16px] text-[#2E3646]">
                        {item.date}
                      </p>
                    </div>
                    <div className="mt-3">
                      <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                        {item.example}
                      </p>
                    </div>
                    <div className="mt-3">
                      <p className="text-center font-inter font-[500] text-[16px] text-[#2E3646]">
                        {item.afternoon}
                      </p>
                    </div>
                    <div className="mt-3">
                      <button className="font-inter font-[400] text-[12px] text-[#5E83F5] border-[1px] border-[#5E83F5] rounded-full bg-[#e5ebf9] py-1 px-4 block mx-auto">
                        {item.appro}
                      </button>
                    </div>
                    <div className="mt-3">
                      <p className="cursor-pointer text-center font-inter font-[500] text-[16px] text-[#5E83F5]">
                        {item.cla}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-10">
              <p className="font-inter font-[700] text-[15px] md:text-[18px] text-black">
              Histoire de la consultation


              </p>
              <img src={Calendar} alt="" className="my-5" />

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
