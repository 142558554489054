import React from "react";
import { useNavigate } from "react-router-dom";

export default function Sidebar() {
  let navigate = useNavigate();
  const handleNavigate = (link) => {
    navigate(link);
  };
  return (
    <>
      <div
        style={{
          background: " rgba(59, 50, 239, 0.7)",
        }}
        className="py-5 h-[100vh] relative  hidden lg:block"
      >
        <p
          onClick={() => handleNavigate("/")}
          className="font-inter font-[700] text-[25px] xl:text-[35px] text-white text-center"
        >
          SigProS
        </p>

        <div className="pl-5  mt-14 xl:mt-14">
          <p
            onClick={() => handleNavigate("/home")}
            className="flex cursor-pointer gap-2 font-[500] text-[10px] xl:text-[13px] text-white hover:text-[#8D6AFF] hover:bg-white p-4 rounded-l-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-3 xl:w-4 h-3 xl:h-4 mt-[3px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
            Informations personnelles
          </p>

          <p
            onClick={() => handleNavigate("/service")}
            className="mt-2 xl:mt-5 flex cursor-pointer gap-2 font-[500] text-[10px] xl:text-[13px] text-white hover:text-[#8D6AFF] hover:bg-white p-4 rounded-l-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              className="mt-[3px]"
            >
              <path
                d="M10.5 12.75C11.4665 12.75 12.25 11.9665 12.25 11C12.25 10.0335 11.4665 9.25 10.5 9.25C9.5335 9.25 8.75 10.0335 8.75 11C8.75 11.9665 9.5335 12.75 10.5 12.75Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.5 5.75C4.4665 5.75 5.25 4.9665 5.25 4C5.25 3.0335 4.4665 2.25 3.5 2.25C2.5335 2.25 1.75 3.0335 1.75 4C1.75 4.9665 2.5335 5.75 3.5 5.75Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.58325 4H9.33325C9.64267 4 9.93942 4.12292 10.1582 4.34171C10.377 4.5605 10.4999 4.85725 10.4999 5.16667V9.25"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.5 5.75V12.75"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Demande de service
          </p>

          <p
            onClick={() => handleNavigate("/inbox")}
            className="mt-2 xl:mt-5 flex cursor-pointer gap-2 font-[500] text-[10px] xl:text-[13px] text-white hover:text-[#8D6AFF] hover:bg-white p-4 rounded-l-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-3 xl:w-4 h-3 xl:h-4 mt-[3px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
              />
            </svg>
            Messagerie
          </p>

          <p
            onClick={() => handleNavigate("/contact")}
            className="mt-2 xl:mt-5 flex cursor-pointer gap-2 font-[500] text-[10px] xl:text-[13px] text-white hover:text-[#8D6AFF] hover:bg-white p-4 rounded-l-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-3 xl:w-4 h-3 xl:h-4 mt-[3px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
            Contacts utiles
          </p>

          <p
            onClick={() => handleNavigate("/medical")}
            className="mt-2 xl:mt-5 flex cursor-pointer gap-2 font-[500] text-[10px] xl:text-[13px] text-white hover:text-[#8D6AFF] hover:bg-white p-4 rounded-l-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-3 xl:w-4 h-3 xl:h-4 mt-[3px]"

            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>
            Mon dossier médical
          </p>
        </div>

        <button className="absolute bottom-10 translate-x-[5%] xl:translate-x-[25%] mx-auto flex gap-2 font-[400] text-[14px] xl:text-[16px] text-white border-[1px] border-white py-2 px-6 xl:px-10  rounded-full hover:bg-white hover:text-[#8D6AFF]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
            />
          </svg>
          Logout
        </button>
      </div>
    </>
  );
}
