import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./inter/stylesheet.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./pages/landing";
import Service from "./pages/service";
import Inbox from "./pages/inbox";
import Chatscreen from "./pages/chatscreen";
import Contact from "./pages/contact";
import Medical from "./pages/medical";
import Login from "./pages/login";
import Signup from "./pages/signup";

function App() {
  return (
    <>
      <Router>
        <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/home" element={<Landing />} />
        <Route path="/service" element={<Service />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/chat" element={<Chatscreen />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/medical" element={<Medical />} />


            </Routes>
      </Router>
    </>
  );
}

export default App;
