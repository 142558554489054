import React from "react";
import Logo from "../assets/svg/logo.svg";
import Log from "../assets/png/log.png";
import Msg from "../assets/svg/Icons@2x.svg";
import Lock from "../assets/svg/Icons.svg";
import { useNavigate } from "react-router-dom";

export default function Login() {
  let navigate = useNavigate();
  const handleNavigate = (link) => {
    navigate(link);
  };
  return (
    <>
      <div className="grid md:grid-cols-12  h-[100vh]">
        <div className="w-[90%] md:col-span-6 xl:col-span-7 mx-auto hidden md:block">
          <img src={Logo} alt="" />

          <div className="self-center lg:self-start" >
            <img src={Log} alt="" className="xl:w-[70%] mx-auto" />
          </div>
        </div>

        <div className="bg-[#6D67EB] h-full md:col-span-6 xl:col-span-5">
          <div className="w-[90%] md:w-[80%] mx-auto py-10">
            <p className="font-inter font-[700] text-[40px] text-white">
              SigProS
            </p>

            <div className="bg-white mt-5 xl:mt-10 rounded-xl md:px-5 py-10">
              <p className="text-center font-inter font-[700] text-[35px] text-black">
                Login
              </p>

              <form className="w-[90%] mx-auto mt-10">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Username"
                    className="self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md py-3 px-12 w-full"
                  />
                  <img src={Msg} alt="" className="absolute top-2 left-2 " />
                </div>

                <div className="relative mt-5">
                  <input
                    type="password"
                    placeholder="password"
                    className=" self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md py-3 px-12 w-full"
                  />
                  <img src={Lock} alt="" className="absolute top-2 left-2 " />
                </div>

                <div className="mt-5 flex justify-between">
                  <div className="flex gap-2">
                    <input type="checkbox" />
                    <p className="font-inter font-[400] text-[#1C1C1E] text-[12px] md:text-[14px]">
                      Remember Me
                    </p>
                  </div>
                  <p className="font-inter font-[600] text-[#0049FC] text-[12px] md:text-[14px]">
                    Forget Password?
                  </p>
                </div>
                <button
                  onClick={() => handleNavigate("/home")}
                  className="mt-10 font-inter font-[400]  text-[16px] text-white rounded-md bg-[#0049FC] w-full p-2"
                >
                  Login
                </button>

                <p className="mt-5 font-inter font-[400] text-[12px] md:text-[14px] text-[#1C1C1E]">
                  Don’t have a SigProS account?{" "}
                  <span
                  onClick={() => handleNavigate("/signup")}
                  
                  className="text-[#0049FC] font-[600] ml-1 cursor-pointer">
                    Create new Account
                  </span>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
