import React from "react";
import Logo from "../assets/svg/logo.svg";
import Log from "../assets/png/log.png";
import { useNavigate } from "react-router-dom";

export default function Signup() {
  let navigate = useNavigate();
  const handleNavigate = (link) => {
    navigate(link);
  };
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-12  h-[100vh]">
        <div className=" w-[90%] lg:col-span-5 xl:col-span-5 mx-auto hidden lg:block">
          <img src={Logo} alt="" />

          <div className=" mt-80">
            <img src={Log} alt="" className="xl:w-[70%] mx-auto " />
          </div>
        </div>

        <div className="bg-[#6D67EB] h-full  lg:col-span-7">
          <div className="w-[90%] lg:w-[80%] mx-auto py-10">
            <p className="font-inter font-[700] text-[40px] text-white">
              SigProS
            </p>

            <div className="bg-white mt-5 xl:mt-10 rounded-xl md:px-5 py-10">
              <p className="text-center font-inter font-[700] text-[35px] text-black">
                Create Account
              </p>

              <form className="w-[90%] mx-auto mt-10">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2  w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2  w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Gender
                    </label>
                    <select className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2  w-full">
                      <option>Male</option>
                      <option>Female</option>
                      <option>Other</option>
                    </select>
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-[5px]  w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Place of Birth
                    </label>
                    <input
                      type="text"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2 w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2 w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Email Address
                    </label>
                    <input
                      type="mail"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2 w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Martial Status
                    </label>
                    <select className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-[10px]  w-full">
                      <option>Martial Status</option>
                      <option>Martial Status 2</option>
                      <option>Martial Status 3</option>
                    </select>
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Address
                    </label>
                    <input
                      type="text"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2 w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Street Number
                    </label>
                    <input
                      type="number"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2 w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Street Municipality
                    </label>
                    <input
                      type="mail"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2 w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      District
                    </label>
                    <input
                      type="text"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2 w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      City
                    </label>
                    <input
                      type="text"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2 w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Profession
                    </label>
                    <select className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-[10px]  w-full">
                      <option>Employee</option>
                      <option>Employee</option>
                      <option>Employee</option>
                    </select>
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Activity
                    </label>
                    <input
                      type="text"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2 w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Number of Children
                    </label>
                    <input
                      type="number"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2 w-full"
                    />
                  </div>
                </div>

                <p className="mt-10 font-inter font-[600] text-[16px] text-black">
                  Children*
                </p>
                <div className="grid  gap-5 grid-cols-1 md:grid-cols-2">
                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2  w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-2  w-full"
                    />
                  </div>

                  <div>
                    <label className="font-inter font-[400] text-[10px] text-black">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      className="mt-1 self-center font-inter font-[500] text-[16px] text-[#1C1C1EB8] border-[1px] border-[#1C1C1EB8] rounded-md p-[5px]  w-full"
                    />
                  </div>
                </div>

                <div className="md:flex justify-end gap-5">
                  <button
                    onClick={() => handleNavigate("/")}
                    className="mt-10 font-inter font-[400]  text-[16px] text-white rounded-md bg-[#2ee600]   py-2 px-10 md:px-8"
                  >
                    Submit Application
                  </button>

                  <button
                    onClick={() => handleNavigate("/")}
                    className="mt-10 font-inter font-[400]  text-[16px] text-white rounded-md bg-[#0049FC]   py-2 px-14 md:px-8"
                  >
                    Add Document
                  </button>
                </div>

                
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
