import React from "react";
import Sidebar from "../component/sidebar";
import Navbar from "../component/navbar";
import Ser from "../assets/png/ser.png";
import Ser2 from "../assets/png/ser2.png";
import Ser3 from "../assets/png/ser3.png";
import Ser4 from "../assets/png/ser4.png";
import Dot from "../assets/svg/dot.svg";

const list = [
  {
    img: Ser,
    title: "Santé",
    des: "Lorem ipsum dolor sit amet consectetur. Sit luctus felis convallis diam vulputate. Euismod euismod a nulla amet platea facilisi nunc placerat.amet platea facilisi nunc placerat.",
    dot: Dot,
  },
  {
    img: Ser2,
    title: "Invalidité",
    des: "Lorem ipsum dolor sit amet consectetur. Sit luctus felis convallis diam vulputate. Euismod euismod a nulla amet platea facilisi nunc placerat.amet platea facilisi nunc placerat.",
    dot: Dot,
  },
  {
    img: Ser3,
    title: "Retraite",
    des: "Lorem ipsum dolor sit amet consectetur. Sit luctus felis convallis diam vulputate. Euismod euismod a nulla amet platea facilisi nunc placerat.amet platea facilisi nunc placerat.",
    dot: Dot,
  },
  {
    img: Ser4,
    title: "La mort",
    des: "Lorem ipsum dolor sit amet consectetur. Sit luctus felis convallis diam vulputate. Euismod euismod a nulla amet platea facilisi nunc placerat.amet platea facilisi nunc placerat.",
    dot: Dot,
  },
  {
    img: Ser2,
    title: "Invalidité",
    des: "Lorem ipsum dolor sit amet consectetur. Sit luctus felis convallis diam vulputate. Euismod euismod a nulla amet platea facilisi nunc placerat.amet platea facilisi nunc placerat.",
    dot: Dot,
  },
  {
    img: Ser,
    title: "Santé",
    des: "Lorem ipsum dolor sit amet consectetur. Sit luctus felis convallis diam vulputate. Euismod euismod a nulla amet platea facilisi nunc placerat.amet platea facilisi nunc placerat.",
    dot: Dot,
  },
];

export default function Service() {
  return (
    <>
      <div className="grid lg:grid-cols-12">
        <div className="lg:col-span-2 2xl:col-span-2 ">
          <Sidebar />
        </div>
        <div className="lg:col-span-10 2xl:col-span-10 h-[100vh] overflow-y-scroll">
          <Navbar />

          <div className="w-[95%] mx-auto py-10 mt-20">
            <p className="font-inter font-[700] text-[25px] text-black">
              Demande de service
            </p>

            <div className="flex justify-between mt-10">
              <p className="font-inter font-[600] text-[25px] text-black self-center">
                Liste des services
              </p>

              <button className="font-inter font-[500] text-[13px] md:text-[16px] text-white bg-[#8D6AFF] hover:shadow-md py-2 px-3 md:px-5 rounded-full">
                demande
              </button>
            </div>

            {list.map((item) => (
              <div className="grid md:grid-cols-12 bg-white   p-5 rounded-lg mt-5">
                <div className=" ml-auto block md:hidden">
                  <img src={item.dot} alt="" className="cursor-pointer" />
                </div>
                <div className="md:col-span-8 md:flex gap-10 ">
                  <img src={item.img} alt="" className="w-[110px] h-[110px]" />
                  <div className="self-center mt-5 md:mt-0">
                    <p className="font-inter font-[500] text-[25px] text-black">
                      {item.title}
                    </p>
                    <p className="font-inter font-[400] text-[16px] text-[#999999] ">
                      {item.des}
                    </p>
                  </div>
                </div>
                <div className="md:col-span-2 ml-auto self-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 text-[#999999] cursor-pointer hidden md:block"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </div>
                <div className="md:col-span-2 ml-auto hidden md:block">
                  <img src={item.dot} alt="" className="cursor-pointer" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
